import React from "react";
import envConfig from "../../../config/envConfig";
import { examDuration } from "../../../constant/Constants";

const Instructions = ({ setInstructionsModuleOpen }) => {
  return (
    <div
      className={`instructions-data container ${
        setInstructionsModuleOpen && "modal-content-data"
      }`}
    >
      {setInstructionsModuleOpen && (
        <div
          onClick={() => setInstructionsModuleOpen(false)}
          className="close-icon absolute top-6 right-8 text-3xl font-bold bg-[#000]/50 text-white h-10 w-10 flex items-center justify-center rounded-full"
        >
          ×
        </div>
      )}

      <div className="text-center">
        <h3>Instruction For {envConfig.appFullName}</h3>
      </div>
      <img className="hand" src="/img/new-home/hands.png" alt="img" />
      <div className="modal-body-data">
        <div>
          <h6>Webcams :</h6>
          <ul className="list-disc list-inside  space-y-2">
            <li>Keep the Camera ON and working during the competition.</li>
            <li>
              Please refrain from stepping away from your Laptop/Desktop during
              the competition.
            </li>
            <li>
              Please sit in front of proper lighting so that the camera can
              detect your face and background.
            </li>
            <li>
              The competitor will get disqualified if the webcam is turned off
              at any time while giving the competition.
            </li>
          </ul>
        </div>

        <div>
          <h6>Microphone:</h6>
          <ul className="list-disc list-inside space-y-2">
            <li>Keep your microphone ON.</li>
            <li>
              Please do not mute or disconnect the microphone during the
              competition.
            </li>
            <li>Please make sure that no background noise is detected.</li>
            <li>Don't Refresh Or Exit Full Screen.</li>
            <li>
              Do not switch/refresh the tab or try to open any other window.
            </li>
            <li>Do not open the development or any other tool for help.</li>
            <li>Do not move the cursor out of the competition tab.</li>
            <li>
              Do not Press the 'Back Button' while taking the competition.
            </li>
            <li>
              Do not exit 'Full-Screen' mode while taking the competition.
            </li>
          </ul>
        </div>

        <div>
          <h6>Challenge Instructions:</h6>
          <ul className="list-disc list-inside space-y-2">
            <li>
              Please read all the instructions carefully before you start.
            </li>
            <li>Join 15 min before Challenge Start time.</li>
            <li>
              Attempt maximum questions with correct answers in {examDuration}{" "}
              mins to become a winner.
            </li>
            <li>E-Certificate to all participants.</li>
            <li>This is a skill-based Competition.</li>
            <li>This Competition contains 40 Questions.</li>
            <li>The participants will have 1 hour to complete the test.</li>
            <li>
              The winner will be judged based on who provides the maximum number
              of correct answers in the minimum time.
            </li>
            <li>
              Each question is Time-based, failing to submit the answer in time
              will automatically result in moving to the next question.
            </li>
            <li>
              You'll not be able to get back to previous questions, so mark the
              Answers Carefully.
            </li>
            <li>
              You will get 3 warnings, violating these warnings will result in
              the 'Disqualification' of the test directly.
            </li>
            <li>You should have a stable internet connection.</li>
            <li>
              The microphone should remain Unmuted and there should be no
              Background Noise.
            </li>
            <li>
              The web camera should be ON and working at all times while taking
              the test.
            </li>
            <li>
              Violating the given instructions will result in disqualification
              from the competition.
            </li>
          </ul>
        </div>

        <div>
          <h6>
            Remember, a warning will be triggered if you try the following:
          </h6>
          <ul className="list-disc list-inside space-y-2">
            <li>Exiting the full-screen mode of the browser.</li>
            <li>
              Moving the Cursor outside the window or hovering the cursor on
              other Tabs.
            </li>
            <li>Switching the Tab or opening other windows.</li>
            <li>Opening Browsers developer / Inspect tools.</li>
            <li>Minimizing or resizing the window.</li>
            <li>Recording or taking Screenshots during the competition.</li>
          </ul>
        </div>

        <div>
          <h6>Date:</h6>
          <ul className="list-disc list-inside">
            <li>October, 2024 Onwards</li>
          </ul>
        </div>

        <div>
          <h6>Fees:</h6>
          <ul className="list-disc list-inside">
            <li>
              After payment, refunds are not applicable as it is non-refundable.
            </li>
          </ul>
        </div>

        <div>
          <p>
            Please contact us for any help on our customer support email us to
            <a
              href="mailto:hello@practido.com"
              className="mx-1 hover:underline"
            >
              hello@practido.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
