export const registerSuccessEmail = (userName, userEmail, participants) => {
  return `
        <div>
            <div>
                <p>Dear <span style='text-transform: capitalize;'>${userName}</span>,</p>
                Thank you for registering your child on the Practido Young Learners Challenge App. We are thrilled to have them on board and look forward to supporting their academic growth through our comprehensive testing platform.
            </div>
            <p>
                Below, you'll find a summary of the registration details:
            </p>
            <div>
                <b>Exam Credentials - </b><br>
                <div style='margin-left: 20px'>
                    <b>Chalange Login ID - </b> ${userEmail}
                    <br/>
                    <b>Chalange Login Link - </b>
                    <a href="${process.env.REACT_APP_URL}/examLogin">
                        ${process.env.REACT_APP_URL}/examLogin
                </div>
            </div>
            <p>
                <b>Participant Information :</b>
            </p>
            <p  style='margin-left: 20px'>
                ${participants
                  .map((participant, index) => {
                    return `
                        <b>Participant Name - ${participant.name} </b><br/>
                        <b>Grade/Class - ${participant.class} </b><br/>
                        <p style= 'margin-left: 40px'>
                            ${participant.subject
                              .map((sub, i) => {
                                return `
                                    <b>Subject - ${sub} </b><br/>
                                    Exam Code - ${participant.examCode[i]}<br/>
                                    Exam Date - ${new Date(
                                      participant.examDateTime[i].seconds * 1000
                                    )
                                      .toLocaleDateString("en-IN", {
                                        year: "numeric",
                                        month: "short",
                                        day: "2-digit",
                                      })
                                      .replaceAll("-", " ")}<br/>
                                    Exam Time - ${new Date(
                                      participant.examDateTime[i].seconds * 1000
                                    )
                                      .toLocaleTimeString("en-IN", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                        hourCycle: "h11",
                                      })
                                      .replace(":", " : ")}<br/>
                                `;
                              })
                              .join("")}
                        </p>
                    <br/>`;
                  })
                  .join("")}
            </p>
            <p>
                If you have any questions or need further assistance, feel free to contact our support team at ${
                  process.env.REACT_APP_EMAIL
                }
            </p>
            <p>
                We wish your child the best of luck in the upcoming Practido Tests. Thank you for entrusting us with their educational journey.
            </p>
            <div>
                Best Regards,<br>
                <b>Team ${process.env.REACT_APP_NAME}</b>
            </div>
        </div>
    `;
};

export const examSubmitEmail = (userName, participantName) => {
  return `
        <div>
        Dear ${userName},
        <br/>
        <p>We hope this email finds you well.
        <br/>
        <b>Thank you</b> for choosing the Practido - Olympiad Practice App to support your child's academic journey. We understand the importance of nurturing young minds and are delighted to be a part of your child's educational experience.</p>
        
        <p>We are excited to share the results of the recent tests your child, <b>${participantName}</b> took on the Practido - Olympiad Practice App. Attached to this email, you will find a PDF document containing detailed answers to the questions from the test. This comprehensive report aims to provide insights into your child's performance, helping you understand their strengths and areas where they can further enhance their skills.</p>
        Thanks & Regards<br/>
        <b>Team ${process.env.REACT_APP_NAME}</b>
        </div>
    `;
};

export const constactFormEmail = (name, email, phone, message) => {
  return `
        <div>
            <p>
                Name : ${name}<br>
                Email : ${email}<br>
                Phone : ${phone}<br>
            </p>
            <p>
                Message :<br/> 
                ${message}
            </p>
        </div>
    `;
};
