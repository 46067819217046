import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../landingPage/Header";
import SetupLeft from "./SetupLeft";
import SetupRight from "./SetupRight";

const Setup = ({ setisScreenSharing }) => {
  const steps = [
    "camera",
    "mic",
    "internet",
    "instruction",
    // "screenRecording",
  ];
  const { step } = useParams();

  const [hasCameraPermission, sethasCameraPermission] = useState();
  const [hasMicPermission, sethasMicPermission] = useState();
  const [isMicWorking, setisMicWorking] = useState();
  const [hasInternetConnection, setHasInternetConnection] = useState();

  const [participant, setParticipant] = useState([]);
  useEffect(() => {
    const participantDetails = JSON.parse(
      localStorage.getItem("practido-studentDetails")
    );
    if (participantDetails) {
      setParticipant({
        ...participantDetails?.student,
        examSubject: participantDetails?.subject,
      });
    }
  }, []);

  return (
    <div className="">
      <Header
        placement="examModule"
        // name={participant.name}
        // photoUrl={participant.imageUrl}
      />
      <div className="w-full h-[100vh] flex items-center justify-center pt-20">
        <div className="bg-white w-[90%] flex rounded-[25px] shadow-[0_0_20px_rgba(0,0,0,0.05)] overflow-hidden">
          <div className="w-1/2 bg-[#FFE9C8] flex items-center justify-center">
            <SetupLeft
              step={step}
              hasCameraPermission={hasCameraPermission}
              hasMicPermission={hasMicPermission}
              setisMicWorking={setisMicWorking}
              hasInternetConnection={hasInternetConnection}
              setHasInternetConnection={setHasInternetConnection}
            />
          </div>
          <div className="w-[50%] h-[75vh] flex flex-col">
            <div className="flex gap-3 my-5 mx-[5%]">
              {steps.map((stp, index) => (
                <div
                  key={index}
                  className="h-[5px] w-full"
                  style={{
                    backgroundColor: step === stp ? "#F39C12" : "#E4E4E4",
                  }}
                />
              ))}
            </div>
            <SetupRight
              step={step}
              steps={steps}
              sethasCameraPermission={sethasCameraPermission}
              hasCameraPermission={hasCameraPermission}
              hasMicPermission={hasMicPermission}
              sethasMicPermission={sethasMicPermission}
              // setisScreenSharing={setisScreenSharing}
              isMicWorking={isMicWorking}
              hasInternetConnection={hasInternetConnection}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setup;
