import { useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const MicProctoring = ({
  voiceVolume,
  setVoiceVolume,
  listning,
  setLoadingPercent,
}) => {
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);

  useEffect(() => {
    const initAudio = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        const audioCtx = new (window.AudioContext ||
          window.webkitAudioContext)();
        const mic = audioCtx.createMediaStreamSource(stream);
        const analyserNode = audioCtx.createAnalyser();
        mic.connect(analyserNode);
        analyserNode.fftSize = 256;
        setAudioContext(audioCtx);
        setAnalyser(analyserNode);
      } catch (error) {
        console.log("Error accessing microphone:", error);
        console.log(
          "Microphone access is blocked. Please enable it to proceed."
        );
      } finally {
        setLoadingPercent((prev) => prev + 10);
      }
    };
    initAudio();
    return () => {
      if (audioContext) {
        audioContext.close();
      }
    };
  }, []);

  useEffect(() => {
    if (analyser) {
      const dataArray = new Uint8Array(analyser.frequencyBinCount);

      const detectSound = () => {
        analyser.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((a, b) => a + b) / dataArray.length;
        setVoiceVolume(Math.round(average));

        requestAnimationFrame(detectSound);
      };
      detectSound();
    }
  }, [analyser]);
  return (
    <div className="w-full h-[10px] bg-gray-400">
      <div
        className="bg-blue-500 h-full rounded-r-full"
        style={{ width: `${voiceVolume}%` }}
      ></div>
    </div>
  );
};

export default MicProctoring;
