import React, { useState } from "react";
import { ReactInternetSpeedMeter } from "react-internet-meter";
import ReactSpeedometer from "react-d3-speedometer";
import "react-internet-meter/dist/index.css";
import { Button } from "../../../../components";

function InternetMeter({ speed, setSpeed }) {
  const [testing, setTesting] = useState(false);

  return (
    <div className="flex flex-col items-center justify-center">
      <ReactSpeedometer
        value={Number(speed)}
        minValue={0}
        maxValue={200}
        segments={1000}
        maxSegmentLabels={5}
        needleColor="#666"
        startColor="red"
        endColor="green"
        ringWidth={10}
        labelFontSize="16px"
        valueTextFontSize="24px"
        paddingVertical={10}
        paddingHorizontal={10}
        needleTransition="easeElastic"
        needleTransitionDuration={4000}
      />
      <div className="-mt-20">
        {testing ? (
          ""
        ) : (
          <Button
            version="black"
            text="Test"
            width="6rem"
            handleClick={() => setTesting(true)}
          />
        )}
      </div>

      {testing && (
        <div>
          <ReactInternetSpeedMeter
            txtSubHeading="Your internet speed is"
            outputType="alert"
            thresholdUnit="megabyte"
            threshold={10}
            pingInterval={5000}
            imageUrl="https://naskay.in/img/homepage/Team_BW.webp"
            downloadSize="1781287"
            callbackFunctionOnNetworkTest={(internetSpeed) =>
              setSpeed(internetSpeed)
            }
          />
        </div>
      )}
    </div>
  );
}

export default InternetMeter;
