import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../../../config/firebaseConfig";

export const fetchQuestionsData = async (subject, candidateClass) => {
  // fetch docs form firebase
  const q = query(
    collection(db, "questions"),
    where("subject", "==", subject),
    where("className", "==", candidateClass)
  );
  const questions = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    questions.push({ ...doc.data(), id: doc.id });
  });
  return questions;
};

export const submitExam = async ({ results, startTime, participantId }) => {
  const totalQuestions = results.length;
  const timeTaken = Date.now() - startTime;
  const correctAnswers = results?.filter(
    (result) => result?.evaluation === "correct"
  )?.length;
  const attemptedQuestions = results?.filter(
    (result) => result?.evaluation !== "unattempted"
  ).length;
  const score = Math.round((correctAnswers / totalQuestions) * 100);
  //update Result data to firestore db
  const resultData = {
    participantId,
    totalQuestions,
    attemptedQuestions,
    correctAnswers,
    score,
    timeTaken,
    quesionsResults: results,
    startTime: Timestamp.fromDate(new Date(startTime)),
  };

  try {
    const resultRef = await addDoc(collection(db, "results"), resultData);
    return resultRef;
  } catch (error) {
    throw error;
  }
};

export const sendExamSubmitEmail = async () => {
  //   const userName = auth.currentUser.displayName;
  //   const userEmail = auth.currentUser.email;
  //   const participantName = participantDetails.name;
  //   let mailApi = envConfig.emailApi;
  //   const emailSubject = `Olympiad Result - ${participantName}`;
  //   const emailMessage = examSubmitEmail(userName, participantName);
  //   const emailData = JSON.stringify([userEmail, emailSubject, emailMessage]);
  //   axios.post(mailApi, emailData);
};

export const clearAllLocalStorage = () => {
  //   localStorage.removeItem("participantId");
  //   localStorage.removeItem("refresh");
};

export const handleFullScreen = () => {
  if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen();
  } else if (document.documentElement.webkitRequestFullscreen) {
    document.documentElement.webkitRequestFullscreen();
  }
};

export const preventCopyPaste = (e) => {
  // Prevent Ctrl+S, Ctrl+C & Ctrl+V
  e = e || window.event;
  if (e.ctrlKey) {
    let eventCode = e.code;
    if (
      ["ShiftLeft", "ShiftRight", "KeyS", "KeyC", "KeyX", "KeyV"].includes(
        eventCode
      )
    ) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
};

export const preventScreenShot = (e) => {
  // Prevent PrintScreen
  e = e || window.event;
  if (e.code === "PrintScreen") {
    e.preventDefault();
    e.stopPropagation();
    return true;
  } else {
    return false;
  }
};

// const examFunctions = {
// showModule: (module) => {
//   module?.classList.remove("hidden");
//   module?.classList.add("flex");
// },
// hideModule: (module) => {
//   module?.classList.remove("flex");
//   module?.classList.add("hidden");
// },
