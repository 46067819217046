import React, { useState } from "react";

import { Button, Checkbox } from "../../../components";

const InstructionsMainPanel = ({ handleStart, loading, timeRemaining }) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className="w-[85%] mx-auto py-6">
      <h1 className="heading1 mb-3">Hey there! </h1>
      <div className="gap-6 flex flex-col text-base ml-4">
        <div className="flex w-full items-center gap-4">
          <span className="text-3xl">🚀</span>
          <p className="font-medium text-lg">
            You're about to do a fun and skill-based challenge.{" "}
            <span className="font-bold">
              Here are some important things to know:
            </span>
          </p>
        </div>
        <div className="flex w-full items-center gap-4">
          <span className="text-3xl">⏰</span>
          <p className="font-medium text-lg">
            <span className="font-bold">Time Matters : </span>
            Each question has a timer. Finish before it runs out to move to the
            next one. You can't go back, so pick your answers carefully.
          </p>
        </div>
        <div className="flex w-full items-center gap-4">
          <span className="text-3xl">⚠️</span>
          <p className="font-medium text-lg">
            <span className="font-bold">Warnings : </span>
            Be careful! You'll get a warning if you try to do things like
            leaving full-screen, moving your cursor away, or opening other tabs.
            You get three warnings only. After that, the test stops
            automatically.
          </p>
        </div>
        <div className="flex w-full items-center gap-4">
          <span className="text-3xl">🌐</span>
          <p className="font-medium text-lg">
            <span className="font-bold">Internet and Tech Stuff : </span>
            Make sure your internet is good. Keep your microphone on and don't
            make noise in the background. Your camera needs to be on the whole
            time too.
          </p>
        </div>
        <div className="flex w-full items-center gap-4">
          <span className="text-3xl">❌</span>
          <p className="font-medium text-lg">
            <span className="font-bold">No Recording : </span>
            Don't try to take screenshots or record. If you break the rules, the
            test stops.
          </p>
        </div>
        <div className="flex w-full items-center gap-4">
          <span className="text-3xl">🤝</span>
          <p className="font-medium text-lg">
            <span className="font-bold">Promise : </span>
            Say "I agree" to promise you'll follow these rules. It's important
            to do your best and have fun!
          </p>
        </div>
      </div>
      <div className="ml-4 flex items-center">
        <Checkbox
          checkId="acceptInstructions"
          handleChange={() => setChecked(!checked)}
          labelText=""
        />
        <label
          htmlFor="acceptInstructions"
          className="font-bold text-lg cursor-pointer pl-[20px] text-[#F68B29]"
        >
          I agree and will make sure not to violate any of the above-given
          instructions.
        </label>
      </div>

      {/* {timeRemaining?.timeRemaining > 0 &&
                <div className='text-base text-[#] mb-2 flex items-center gap-2 text-[#E74C3C]'>
                    <span>
                        You can start your Challange in
                    </span>
                    -
                    <span className='font-semibold'>
                        {timeRemaining?.minutes}Mins {timeRemaining?.seconds}Sec
                    </span>
                </div>
            } */}

      <div>
        <Button
          version="secondary"
          // isDisabled={(checked && timeRemaining?.timeRemaining < 0) ? false : true}
          isDisabled={!checked}
          loading={loading}
          handleClick={handleStart}
          text="Start"
          width="8rem"
        />
      </div>
    </div>
  );
};

export default InstructionsMainPanel;
