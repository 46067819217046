import React, { useEffect, useState } from "react";
import { Button, TextInput } from "../../../components";
import { validateEmail } from "../../../assets/js/validation";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  // signOut,
} from "firebase/auth";
import {
  auth,
  db,
  // participantsCollectionRef,
} from "../../../config/firebaseConfig";
import {
  doc,
  // getDoc,
  onSnapshot,
  // orderBy,
  // query,
  // where,
} from "firebase/firestore";
// import { countDown } from "../../../assets/js/countDown";
// import { TbLogout } from "react-icons/tb";

const ExamLoginRight = () => {
  const [user, setUser] = useState(null);
  const [studentDetails, setStudentDetails] = useState(null);
  const urlParam = new URLSearchParams(window.location.search);
  const studentId = urlParam.get("student");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup when component unmounts
  }, []);

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const studentDocRef = doc(db, "participants", studentId);
        onSnapshot(studentDocRef, async (document) => {
          if (!document.exists()) {
            console.log("No student document!");
            return setError((prev) => ({
              ...prev,
              studentDetails: "No student",
            }));
          }
          // const parentId = await document?.data()?.parentDocId;
          // if (parentId) {
          // const parentDocRef = doc(db, "parents", parentId);
          // const parentDoc = await getDoc(parentDocRef);
          setStudentDetails({
            ...document.data(),
            // parent: { ...parentDoc?.data(), id: parentDoc?.id },
          });
          // }
        });
      } catch (error) {
        console.log(error);
      }
    };
    if (user && studentId) {
      fetchStudentDetails();
    }
  }, [user, studentId]);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    const formData = e.target;
    const email = !user && formData?.email?.value;
    const pass = !user && formData?.password?.value;
    const examCode = formData?.examCode?.value;

    if (!user) {
      if (!validateEmail(email)) {
        setError((prev) => ({
          ...prev,
          email: "Please enter a valid email id",
        }));
      }
      if (pass === "") {
        setError((prev) => ({ ...prev, pass: "Password cannot be empty" }));
      }
    }
    if (examCode === "") {
      setError((prev) => ({ ...prev, code: "Exam Code cannot be empty" }));
    }
    if ((!user && (!validateEmail(email) || pass === "")) || examCode === "")
      return;

    try {
      setLoading(true);
      if (!user) {
        await signInWithEmailAndPassword(auth, email, pass);
      }

      if (!studentDetails) {
        // const currentUserParticipants = query(
        //   participantsCollectionRef,
        //   where("parentUid", "==", user.uid)
        // );
        // onSnapshot(currentUserParticipants, (snap) => {
        //   let data = snap.docs.map((doc) => {
        //     return { ...doc.data(), id: doc.id };
        //   });
        //   examLogin(data, examCode);
        // });
      } else {
        await examLogin(studentDetails, examCode);
      }
    } catch (error) {
      error.message === "Firebase: Error (auth/invalid-credential)."
        ? setError((prev) => ({
            ...prev,
            submit: "Invalid Email Id or Password",
          }))
        : setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const examLogin = async (participants, examCode) => {
    const subject = participants?.subjects?.find(
      (sub) => sub.examCode === examCode
    );

    if (!subject) {
      return setError((prev) => ({ ...prev, submit: "Invalid Exam Code" }));
    } else {
      localStorage.removeItem("refresh");
      localStorage.setItem(
        "practido-studentDetails",
        JSON.stringify({
          student: studentDetails,
          subject: subject?.subject,
        })
      );
      window.location.href = "/examSetup/camera";
    }

    // if (!participantToLogin) {
    //   return setError((prev) => ({ ...prev, submit: "Invalid Exam Code" }));
    //   } else {
    //     const timeRemaining = countDown(
    //       participantToLogin.examDateTime.seconds * 1000
    //     );
    //     const expTimeRemaining = countDown(
    //       participantToLogin.examDateTime.seconds * 1000 + 3600000
    //     );
    //     if (expTimeRemaining.timeRemaining <= 0) {
    //       return setError(
    //         `Hi ${participantToLogin.name}, Your challange time slot has been expired`
    //       );
    //     } else if (timeRemaining.timeRemaining > 15 * 60 * 1000) {
    //       return setError(
    //         `Hi ${participantToLogin.name}, you can only enter 15 min before your exam date time`
    //       );
    //     } else if (timeRemaining.timeRemaining < 15 * 60 * 1000) {
    //       if (participantToLogin.attempted) {
    //         // already attempted
    //         return setError(
    //           `Hi ${participantToLogin.name}, You have already attempted the test.`
    //         );
    //       } else {
    //         localStorage.removeItem("refresh");
    //         localStorage.setItem(
    //           "participantId",
    //           JSON.stringify(participantToLogin.id)
    //         );
    //         window.location.href = "/examSetup";
    //       }
    //     }
    // }
  };

  return (
    <div className="w-full h-full flex flex-col justify-center p-5">
      <div className="mb-5">
        <img src="/img/big-logo.png" className="w-[160px]" alt="" />
      </div>
      <div className="w-full lg:w-4/5">
        <form className="flex flex-col gap-4 w-full" onSubmit={handleLogin}>
          {user ? (
            <div>
              <div className="flex flex-col gap-1 w-full">
                <label htmlFor="user" className="font-semibold">
                  Candidate Logged In :
                </label>
                {studentId && (
                  <div className="flex items-center gap-3 bg-[#FFE9C8] p-2 px-3 rounded-xl">
                    <div>
                      <img
                        src={studentDetails?.imageUrl || "/img/profile.png"}
                        alt="profile"
                        className="w-14 bg-[#ccc] rounded-lg overflow-hidden"
                      />
                    </div>
                    <div className="flex flex-col justify-center w-fulls">
                      <div className="flex items-center w-full gap-2">
                        <span>Name : </span>
                        <strong>{studentDetails?.name}</strong>
                      </div>
                      <div className="flex items-center w-full gap-2">
                        <span>Class : </span>
                        <strong>
                          {studentDetails?.className}
                          {studentDetails?.className === 1
                            ? "st"
                            : studentDetails?.className === 2
                            ? "nd"
                            : studentDetails?.className === 3
                            ? "rd"
                            : "th"}
                        </strong>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <div>
                <label htmlFor="email" className="font-medium text-sm">
                  Email Id<sup>*</sup>
                </label>
                <TextInput
                  type="email"
                  placeholderText="Enter your Email id"
                  error={error?.email}
                  inputName="email"
                  // isRequired={true}
                />
              </div>
              <div>
                <label htmlFor="password" className="font-medium text-sm">
                  Password<sup>*</sup>
                </label>
                <TextInput
                  type="password"
                  placeholderText="Enter your Password"
                  error={error?.pass}
                  inputName="password"
                  // isRequired={true}
                />
              </div>
            </>
          )}

          <div>
            <label
              htmlFor="examCode"
              className="font-medium text-sm leading-[12px]"
            >
              Exam Code<sup>*</sup>
            </label>

            <TextInput
              type="text"
              placeholderText="Exam Code"
              error={error?.code}
              inputName="examCode"
              // isRequired={true}
            />
          </div>

          <div className="flex items-start gap-4 flex-col">
            <>
              <Button
                version="secondary"
                text="Proceed"
                type="submit"
                loading={loading}
                inputId="loginButton"
              />
              {error?.submit && (
                <div className="font-medium text-sm leading-4 text-[#f00]">
                  {error?.submit}
                </div>
              )}
            </>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExamLoginRight;
