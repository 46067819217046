import React, { useEffect } from "react";
import { Button } from "../../../../components";
import { useNavigate } from "react-router-dom";

const CameraRight = ({ hasCameraPermission, sethasCameraPermission, step }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        sethasCameraPermission(true);
      })
      .catch((err) => {
        console.log(err);
        sethasCameraPermission(false);
        // askForCameraPermission()
      });
  }, [step, sethasCameraPermission]);

  return (
    <div className="flex flex-col items-start justify-between h-full w-[80%] mx-auto pb-10">
      <div className="flex flex-col">
        <h1 className="heading1 mb-8">
          Webcam Mandatory while giving Assessment Test
        </h1>
        <ul className="flex flex-col gap-4 text-[#444] listDisc text-base list-outside list-disc">
          <li>Always keep the Camera ON and working during the assessment.</li>
          <li>
            Please do not leave your Laptop/PC while the test is going on.
          </li>
          <li>
            Please sit in front of proper lighting so that the camera can detect
            your face and background.
          </li>
          <li>
            The test will get terminated if the webcam is turned off at any time
            while giving the test.
          </li>
        </ul>
      </div>
      <Button
        version="secondary"
        text="Proceed"
        handleClick={() => {
          navigate("/examSetup/mic");
        }}
        width="8rem"
        isDisabled={!hasCameraPermission}
      />
    </div>
  );
};

export default CameraRight;
