import React from "react";
import { motion } from "framer-motion";
import CameraLeft from "./camera/CameraLeft";
import MicLeft from "./mic/MicLeft";
import { InternetLeft } from "./internet/InternetLeft";
// import { InternetLeft } from "./InternetLeft";

const SetupLeft = ({
  step,
  hasCameraPermission,
  hasMicPermission,
  sethasMicPermission,
  setisMicWorking,
  setHasInternetConnection,
}) => {
  return (
    <>
      {step === "camera" && (
        <CameraLeft hasCameraPermission={hasCameraPermission} />
      )}

      {step === "mic" && (
        <MicLeft
          hasMicPermission={hasMicPermission}
          sethasMicPermission={sethasMicPermission}
          setisMicWorking={setisMicWorking}
        />
      )}

      {step === "internet" && (
        <InternetLeft setHasInternetConnection={setHasInternetConnection} />
      )}

      {step === "instruction" && (
        <div className="flex flex-col justify-center items-center">
          <motion.div
            className="z-10"
            transition={{ ease: "linear", duration: 0.5 }}
            variants={{
              initial: { scale: 1 },
              final: { scale: 2 },
            }}
            animate="final"
          >
            <img src="/img/refresh.png" alt="refresh" width="120px" />
          </motion.div>
        </div>
      )}
    </>
  );
};

export default SetupLeft;
