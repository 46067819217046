import React, { useState, useEffect } from "react";
import InstructionsMainPanel from "./InstructionsMainPanel";
import { useNavigate } from "react-router-dom";
import ExamTopBar from "../../../components/Bars/ExamTopBar";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firebaseConfig";
import { handleFullScreen } from "../exam/examFunctions";

const ExamInstructions = () => {
  const [participant, setParticipant] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [timeRemaining, setTimeRemaining] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const participantDetails = JSON.parse(
      localStorage.getItem("practido-studentDetails")
    );
    if (participantDetails) {
      setParticipant({
        ...participantDetails?.student,
        examSubject: participantDetails?.subject,
      });
    }
  }, []);

  // useEffect(() => {
  //   const timeRem = countDown(participant?.examDateTime?.seconds * 1000);
  //   setTimeRemaining(timeRem);
  // }, [participant]);

  const updateAtemptedData = async () => {
    // update attempted data
    const docRef = doc(db, "participants", participant?.enrollmentNo);
    try {
      await updateDoc(docRef, {
        attempted: true,
      });
    } catch (error) {
      throw error;
    }
  };

  const handleStart = async () => {
    try {
      setLoading(true);
      localStorage.removeItem("refresh");
      handleFullScreen();

      //adding attempted data to firebase
      await updateAtemptedData();
      navigate("/examPage");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative w-full overflow-y-auto scrollable">
      <ExamTopBar
        placememt="instructionsPage"
        name={participant.name}
        participantClass={participant.className}
        subject={participant.examSubject}
      />
      <div className="mt-[200px]">
        <InstructionsMainPanel
          handleStart={handleStart}
          // timeRemaining={timeRemaining}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ExamInstructions;
