import React, { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { Button, RadioInput } from "../../../components";
const ExamOptionsPanel = ({
  questions,
  qusNum,
  loading,
  handleNext,
  alertSkip,
  // submitExam,
}) => {
  const options = ["A", "B", "C", "D"];
  const [question, setQuestion] = useState();
  // const [lastQuestion, setLastQuestion] = useState(false);
  const optionFormRef = useRef(null);

  useEffect(() => setQuestion(questions[qusNum]), [questions, qusNum]);

  const submitAnswer = (e) => {
    e.preventDefault();
    const form = optionFormRef.current;
    let userAnswer = form[`q${qusNum + 1}option`]?.value;
    if (userAnswer !== "") {
      handleNext(Number(userAnswer) + 1);
      form.reset();
    } else {
      alertSkip();
    }
  };

  return (
    <div className="relative w-[50%] px-14 h-full flex flex-col justify-between text-base py-4">
      {loading ? (
        <div className="w-full h-full flex justify-center items-center">
          <p className="font-bold text-2xl text-[#bbb]">Loading...</p>
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-4">
            <p className="font-medium text-lg text-[#34495E]">Select any one</p>
            <div className="w-full overflow-y-auto scrollbar-none mb-3">
              <form ref={optionFormRef} className="flex flex-col gap-4">
                {question?.questionType === "subjective" ? (
                  <div className="flex flex-col gap-5">
                    {/* <textarea
                      className="border-[3px] border-[#ECECEC] rounded-[10px] bg-gray-50 focus:ring-0"
                      rows="14"
                      name={`q${qusNum + 1}`}
                      placeholder="Enter your answer here ..."
                      // value={subjectiveAnswer}
                      // onChange={(e) => setSubjectiveAnswer(e.target.value)}
                    /> */}
                  </div>
                ) : (
                  <div className="flex flex-wrap gap-4">
                    {question?.options?.map((option, index) => (
                      <div key={index}>
                        <RadioInput
                          inputName={`q${qusNum + 1}option`}
                          inputValue={index}
                          iniputId={`q${qusNum + 1}${options[index]}`}
                          assessmentInput={true}
                          text={parse(option)}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </form>
            </div>
          </div>

          <div className="flex justify-between">
            {/* {lastQuestion ? (
              <div
                className="p-3 px-5 text-[#FFFAF0] font-semibold text-lg rounded-[20px] bg-[#E74C3C] shadow-[0px_4px_0px_0px_#C0392B] cursor-pointer"
                // onClick={submitExam}
              >
                Submit Exam
              </div>
            ) : ( */}
            <Button
              version="secondary"
              text="Next"
              inputId="next"
              handleClick={submitAnswer}
            />
            {/* )} */}
          </div>
        </>
      )}
    </div>
  );
};

export default ExamOptionsPanel;
