import React from "react";

import { Button } from "../../../components";

const ExamLoginMobile = () => {
  return (
    <div className="flex w-full h-screen flex-col justify-between py-[20%] p-[10%]">
      <div className="flex flex-col">
        <span className="font-medium text-base mb-2">
          Welcome to Naskay Technology’s
        </span>
        <h1 className="font-bold text-xl">Technical Skills Assessment Test</h1>
      </div>
      <div className="flex items-center justify-center text-lg font-semibold text-red-500">
        <p className="text-center">
          Please login via Laptop or PC for best experiance.
        </p>
      </div>

      <div className="flex justify-center">
        <Button
          version="secondary"
          text="Close Tab"
          width="100%"
          handleClick={() => window.close()}
        />
      </div>
    </div>
  );
};

export default ExamLoginMobile;
