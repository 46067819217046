import React from "react";
import { IoClose } from "react-icons/io5";
import { Button } from "..";

const ConfirmationModule = ({
  confirmationMessage,
  confirmationButton,
  cancelAlert,
  confirmSkip,
  confirmSubmit,
}) => {
  return (
    <div className="card flex flex-col justify-center items-center p-[50px] relative">
      {confirmationButton[0] === "Submit" || (
        <div
          onClick={cancelAlert}
          className="flex justify-center items-center absolute top-5 right-5 rounded-full w-8 h-8 bg-gray-300 cursor-pointer"
        >
          <IoClose className="w-6 h-6" />
        </div>
      )}

      <p className="font-semibold text-2xl mb-4">{confirmationMessage[0]}</p>
      <p className="text-lg text-[#919397] w-[360px] text-center">
        {confirmationMessage[1]}
      </p>

      <div className="flex mt-8 gap-5">
        {confirmationButton[0] && (
          <Button
            text={confirmationButton[0]}
            idName="confirmSkip"
            version={confirmationButton[0] === "Submit" ? "secondary" : "gray"}
            handleClick={
              confirmationButton[0] === "Submit"
                ? confirmSubmit
                : confirmationButton[0] === "Skip Question"
                ? confirmSkip
                : () => {}
            }
          />
        )}
        {confirmationButton[1] && (
          <Button
            version="secondary"
            text={confirmationButton[1]}
            handleClick={cancelAlert}
          />
        )}
      </div>
    </div>
  );
};

export default ConfirmationModule;
