import React, { useState, useEffect } from "react";

const Timer = ({ loading, duration = 5, qusNum, timeUp, timerPause }) => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const min = Math.floor(duration);
    const sec = Math.ceil((duration % min) * 100);
    setMinutes(min);
    setSeconds(sec);
  }, [duration, qusNum]);

  useEffect(() => {
    let timerInterval = setInterval(() => {
      if (!timerPause) {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          if (minutes > 0) {
            setMinutes(minutes - 1);
            setSeconds(59);
          } else {
            clearInterval(timerInterval);
            timeUp();
          }
        }
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  });

  return (
    <>
      {loading ? (
        <div className="w-[150px] font-bold text-3xl text-[#bbb] flex justify-center items-center text-center">
          Loading...
        </div>
      ) : (
        <span className="font-medium flex justify-center items-center text-center">
          {minutes === 0 && seconds === 0 ? (
            <span className="text-2xl font-semibold">Time's Up</span>
          ) : (
            <div className="flex items-center">
              <span className="text-xl w-full">Time Remaining</span>
              <span className="w-[50px] text-center text-3xl">
                {minutes ? (minutes < 10 ? `0${minutes}` : minutes) : "00"}
              </span>
              <span className="text-3xl font-bold mx-1"> : </span>
              <span
                className={`w-[50px] text-center text-3xl ${
                  minutes === 0 && seconds < 20 ? "animate-ping" : ""
                }`}
              >
                {seconds ? (seconds < 10 ? `0${seconds}` : seconds) : "00"}
              </span>
            </div>
          )}
        </span>
      )}
    </>
  );
};

export default Timer;
