import React from 'react'

const ExamLoginLeft = () => {
  return (
    <div className='h-full w-full flex flex-col items-center justify-center p-4'>
        <img
            src='img/examLoginLeftPanel.png'
            alt="Studying-img"
            className="w-[350px] lg:w-[500px] mb-4"
        />
        <div className="flex flex-col justify-center items-center">
            <h1 className="font-bold w-[80%] text-center text-xl capitalize mb-2.5">
                Login to your Account
            </h1>
            <p className="w-[90%] text-center mb-5 text-xs lg:text-sm xl:text-base">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pulvinar nisl in molestie posuere tellus odio nunc.
            </p>    
        </div>
    </div>
  )
}

export default ExamLoginLeft
