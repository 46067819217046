import React, { useEffect, useState } from "react";

import ExamTopBar from "../../../components/Bars/ExamTopBar";
import ExamQuestionPanel from "./ExamQuestionPanel";
import ExamOptionsPanel from "./ExamOptionsPanel";
import SubmitModule from "./SubmitModule";
import ExamLoadingModule from "./ExamLoadingModule";
import { ConfirmationModule, WarningModule } from "../../../components";

import {
  fetchQuestionsData,
  submitExam,
  sendExamSubmitEmail,
  clearAllLocalStorage,
  handleFullScreen,
  preventCopyPaste,
  preventScreenShot,
} from "./examFunctions";

import { violationTypes } from "../../../constant/violationTypes";
import devtools from "devtools-detect";

import CameraProctoring from "./proctoring/CameraProctoring";
import MicProctoring from "./proctoring/MicProctoring";
import { load } from "@tensorflow-models/face-landmarks-detection";

// import RecordRTC from 'recordrtc';
// import NoScreenSharing from '../../components/popup/NoScreenSharing';

const ExamPage = () => {
  //states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startTime, setStartTime] = useState();
  const [timer, setTimer] = useState(1);
  const [timerPause, setTimerPause] = useState(false);

  const [participantDetails, setParticipantDetails] = useState(null);
  const [assessmentResults, setAssessmentResults] = useState([]);

  const [confirmationMessage, setConfirmationMessage] = useState([]);
  const [confirmationButton, setConfirmationButton] = useState([]);

  const [warningCount, setWarningCount] = useState(0);
  const [warningType, setWarningType] = useState("");
  const [warningMessage, setWarningMessage] = useState([]);
  const [violations, setViolations] = useState([]);
  const [maxViolations, setMaxViolations] = useState(4);

  const [showWarning, setShowWarning] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSubmitModule, setShowSubmitModule] = useState(false);

  const [loadingPercent, setLoadingPercent] = useState(20);
  // const [proctorLoading, setProctorLoading] = useState(true);
  const [cameraProctorLoading, setCameraProctorLoading] = useState(true);

  // ----- fetching participantDetails from local storage
  useEffect(() => {
    const participant = JSON.parse(
      localStorage.getItem("practido-studentDetails")
    );
    if (participant) {
      setParticipantDetails({
        ...participant?.student,
        examSubject: participant?.subject,
      });
    }
  }, []);

  // fetch questions
  const [questionLoading, setQuestionLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [qusNum, setQusNum] = useState(0);
  useEffect(() => {
    const subject = participantDetails?.examSubject;
    const candidateClass = participantDetails?.className;
    const fetchQuestions = async () => {
      try {
        setQuestionLoading(true);
        const questions = await fetchQuestionsData(subject, candidateClass);
        setQuestions(questions);
      } catch (error) {
        setError(error);
      } finally {
        setQuestionLoading(false);
        setLoadingPercent((prev) => prev + 20);
      }
    };
    if (subject && candidateClass) {
      fetchQuestions();
    }
  }, [participantDetails]);

  useEffect(() => {
    loadingPercent >= 100 && setLoading(false) && setCameraDetection(true);
  }, [cameraProctorLoading, loadingPercent]);

  //exam start time
  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  // set timer
  useEffect(() => {
    questions && setTimer(questions[qusNum]?.duration);
  }, [qusNum, questions]);

  // function to trigger a violation
  const showViolations = (type) => {
    setTimerPause(true);
    setCameraDetection(false);
    // setListning(false);
    setViolations((prev) => [...prev, violationTypes[type]?.violation]);
    setWarningType(type);
    // type !== "voice" && setWarningCount(warningCount + 1);
    setWarningCount(warningCount + 1);
    setWarningMessage(violationTypes[type].message);
    setShowWarning(true);
  };

  // hide warning Module
  const hideModule = () => {
    if (warningCount === maxViolations - 1) {
      setWarningMessage([
        `This is your last warning`,
        "If you violate the assessment rules again, Your assessment will be terminated.",
      ]);
      setWarningType("lastWarning");
    } else {
      closeWarning();
    }
  };
  const closeWarning = () => {
    handleFullScreen();
    setShowWarning(false);
    setTimerPause(false);
    // setcellPhoneDetection(0);
    // setListning(true);
    setCameraDetection(true);
  };

  // Terminate on Refresh
  // useEffect(() => {
  //   const isRefresh = localStorage.getItem("refresh");
  //   if (isRefresh) {
  //     showViolations("refresh");
  //   }
  // }, [warningModule]);
  // const handleBeforeUnload = (e) => {
  //   e.preventDefault();
  //   localStorage.setItem("refresh", true);
  // };
  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  // }, []);

  // Tab switching
  // const [tabSwitch, setTabSwitch] = useState(false);
  // useEffect(() => {
  //   document.addEventListener("visibilitychange", () => {
  //     if (document.visibilityState !== "visible") {
  //       setTabSwitch(true);
  //     } else {
  //       if (tabSwitch) {
  //         showViolations("tabSwitch");
  //       }
  //       setTabSwitch(false);
  //     }
  //   });
  // });

  // full screen detection
  // const fullScreenCheck = () => {
  //   if (!document.fullscreenElement) {
  //     showViolations("fullScreen");
  //   }
  // };
  // useEffect(() => {
  //   // fullScreen check for Chrome,Edge,Safari
  //   document.addEventListener("webkitfullscreenchange", () =>
  //     fullScreenCheck()
  //   );
  //   // fullscreem check for mozilla Firefox
  //   document.addEventListener("mozfullscreenchange", () => fullScreenCheck());
  // });

  //dev tools check
  // useEffect(() => {
  //   window.addEventListener("devtoolschange", (e) => {
  //     if (e.detail.isOpen) {
  //       showViolations("devtools");
  //     } else {
  //       setWarningType("devtools-close");
  //     }
  //   });
  // });

  // prevent copy-paste-save-screenshot
  useEffect(() => {
    document.onkeydown = (e) => {
      preventCopyPaste(e);
    };
    document.onkeyup = (e) => {
      if (preventScreenShot(e)) {
        showViolations("screenShot");
      }
    };
  });

  // // handle multiplt person detiction
  const [faceCount, setFaceCount] = useState();
  const [cameraDetection, setCameraDetection] = useState(true);
  useEffect(() => {
    if (cameraDetection) {
      if (!loading && faceCount === 0) {
        showViolations("noFace");
      } else if (faceCount > 1) {
        showViolations("multiplePerson");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faceCount, cameraDetection, loading]);

  // // check camera enabled
  const [isCameraEnable, setisCameraEnable] = useState(true);

  useEffect(() => {
    if (!loading && cameraDetection && !isCameraEnable) {
      showViolations("cameraDisabled");
    } else {
      setWarningType("cameraEnabled");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCameraEnable, cameraDetection, loading]);

  // //handle cellPhone detection
  // const [cellPhoneDetection, setcellPhoneDetection] = useState(0);
  // useEffect(() => {
  //   if (!loading && cellPhoneDetection > 0) {
  //     showViolations("cellPhoneDetected");
  //   }
  // }, [cellPhoneDetection]);

  // //handle voice detection
  const [voice, setVoice] = useState();
  const [listning, setListning] = useState(true);
  const [noiseTimeout, setNoiseTimeout] = useState(null);
  useEffect(() => {
    if (!loading) {
      // if (voice > 50) {
      //   if (!noiseTimeout) {
      //     setNoiseTimeout(
      //       setTimeout(() => {
      //         console.log("Background noise detected!");
      //         showViolations("voice");
      //         setNoiseTimeout(null);
      //       }, 2000)
      //     );
      //   }
      // } else {
      //   if (noiseTimeout) {
      //     clearTimeout(noiseTimeout);
      //     setNoiseTimeout(null);
      //   }
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voice, loading, listning, noiseTimeout]);

  // // handle Terminate
  // const handleTerminate = () => {
  //   // setViolations([...violations + 'terminate'])
  //   examFunctions.hideModule(warningModule);
  //   handleSubmit();
  // };

  // skip confirmation module
  const alertSkip = () => {
    setTimerPause(true);
    setConfirmationMessage([
      "Do you want to skip ?",
      "You will not be able to come back to this Question later.",
    ]);
    setConfirmationButton(["Skip Question", "Back to Question"]);
    setShowConfirmation(true);
  };

  //confirm skip
  const confirmSkip = () => {
    handleNext("");
    cancelAlert();
  };

  //cancel module
  const cancelAlert = () => {
    setShowConfirmation(false);
    setTimerPause(false);
  };

  //next question button
  const handleNext = (userAnswer) => {
    const question = questions[qusNum];
    let evaluation =
      userAnswer === ""
        ? "unattempted"
        : Number(userAnswer) === Number(question?.correct)
        ? "correct"
        : "incorrect";

    const quesResult = {
      questionNo: qusNum + 1,
      question: question,
      userAnswer: userAnswer || "unattempted",
      // violations: violations,
      evaluation: evaluation,
    };
    setAssessmentResults((prev) => [...prev, quesResult]);

    if (qusNum < questions.length - 1) {
      setQusNum(qusNum + 1);
    } else {
      confirmSubmit();
    }
  };

  //submit confirmation module
  const confirmSubmit = () => {
    setTimerPause(true);
    setConfirmationMessage(["Submit the test ?"]);
    setConfirmationButton(["Submit"]);
    setShowConfirmation(true);
  };

  //submit exam
  const handleSubmit = async () => {
    cancelAlert();
    setTimerPause(true);
    // stop all proctoring

    try {
      await submitExam({
        results: assessmentResults,
        startTime,
        participantId: participantDetails.enrollmentNo,
      });

      // send email
      // await sendExamSubmitEmail();

      // show exam submit module
      // setShowSubmitModule(true);

      // clear local storage
      // clearAllLocalStorage();
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  // Time's Up
  const handleTimeUp = () => {
    if (qusNum !== questions.length - 1) {
      handleNext("");
    } else {
      confirmSubmit();
    }
  };

  return (
    <div
      className="relative bg-gray-300 w-full h-[100vh] flex flex-col items-center justify-center"
      // onContextMenu={(e) => e.preventDefault()}
    >
      <ExamTopBar
        placememt="examPage"
        name={participantDetails?.name}
        participantClass={participantDetails?.className}
        subject={participantDetails?.examSubject}
        qusNum={qusNum}
        duration={timer}
        timeUp={handleTimeUp}
        timerPause={timerPause}
        loading={loading}
      />

      {/* <div className='absolute top-2 left-[50%] translate-x-[-50%] z-50'>
        <Proctoring
          setFaceCount={setFaceCount}
          setVoice={setVoice}
          listning={listning}
          setcellPhoneDetection={setcellPhoneDetection}
          // webcamRef={webcamRef}
          setProctorLoading={setProctorLoading}
          setisCameraEnable={setisCameraEnable}
          setLoadingPercent={setLoadingPercent}
        />
      </div> */}

      <div className="absolute top-2 left-10 z-50 rounded-2xl overflow-hidden shadow-md">
        <CameraProctoring
          setFaceCount={setFaceCount}
          setLoadingPercent={setLoadingPercent}
          setisCameraEnable={setisCameraEnable}
        />
        <MicProctoring
          voiceVolume={voice}
          setVoiceVolume={setVoice}
          listning={listning}
          setLoadingPercent={setLoadingPercent}
        />
      </div>

      {loading && (
        <div className="absolute w-full h-[100vh] top-0 left-0 z-30 flex justify-center items-center bg-black/[0.15] backdrop-blur-[4px]">
          <ExamLoadingModule loadingPercent={loadingPercent} />
        </div>
      )}

      <div className="absolute w-[95%] top-40 h-[78%] flex bg-[white] rounded-xl overflow-hidden p-6">
        <ExamQuestionPanel
          questions={questions}
          qusNum={qusNum}
          loading={loading}
        />
        <ExamOptionsPanel
          loading={loading}
          questions={questions}
          qusNum={qusNum}
          handleNext={handleNext}
          alertSkip={alertSkip}
        />
      </div>

      {showConfirmation && (
        <div id="confirmationModule" className="moduleCover">
          <ConfirmationModule
            confirmationMessage={confirmationMessage}
            confirmationButton={confirmationButton}
            cancelAlert={cancelAlert}
            confirmSkip={confirmSkip}
            confirmSubmit={handleSubmit}
          />
        </div>
      )}

      {showWarning && (
        <div id="warningModule" className="moduleCover">
          <WarningModule
            type={warningType}
            warningCount={warningCount}
            warningMessage={warningMessage}
            hideModule={hideModule}
            maxViolations={maxViolations}
            closeWarning={closeWarning}
            // handleTerminate={handleTerminate}
          />
        </div>
      )}

      {/* {showSubmitModule && (
        <div className="flex w-full h-full fixed top-0 left-0 z-30 justify-center items-center">
          <SubmitModule name={participantDetails?.name} />
        </div>
      )} */}
    </div>
  );
};

export default ExamPage;
