import React from "react";
import ExamLoginLeft from "./ExamLoginLeft";
import ExamLoginRight from "./ExamLoginRight";
import ExamLoginMobile from "./ExamLoginMobile";
import Header from "../../landingPage/Header";
// import ExamLoginInUnsupportedBrowser from "./ExamLoginInUnsupportedBrowser";
// import {
//   BROWSER_CHROME,
//   BROWSER_EDGE,
//   BROWSER_SAFARI,
//   BROWSER_FIREFOX,
//   BROWSER_OTHER,
// } from "../../../constant/Constants";

const Login = () => {
  // const getBrowserName = () => {
  //   let userAgent = navigator.userAgent;
  //   let browserName;
  //   // console.log(JSON.stringify(userAgent))
  //   if (userAgent.match(/chrome|chromium|crios/i)) {
  //     browserName = BROWSER_CHROME;
  //   } else if (userAgent.match(/firefox|fxios/i)) {
  //     browserName = BROWSER_FIREFOX;
  //   } else if (userAgent.match(/safari/i)) {
  //     browserName = BROWSER_SAFARI;
  //   } else if (userAgent.match(/edg/i)) {
  //     browserName = BROWSER_EDGE;
  //   } else {
  //     browserName = BROWSER_OTHER;
  //   }
  //   return browserName;
  // };
  // getBrowserName();

  return (
    <>
      {
        window.innerWidth <= 750 ? (
          <ExamLoginMobile />
        ) : (
          // ) : getBrowserName() === BROWSER_CHROME ||
          //   getBrowserName() === BROWSER_EDGE ||
          //   getBrowserName() === BROWSER_SAFARI ||
          //   getBrowserName() === BROWSER_FIREFOX ? (
          <div className="w-full h-screen flex items-center justify-center bg-[#FFFAF0]">
            <Header placement="dashboard" />
            <div className="flex items-center justify-center mt-16 w-[90%] h-[70%] mx-auto bg-white rounded-[25px] overflow-hidden shadow-md">
              <div className="w-[50%] h-full bg-[#FFE9C8]">
                <ExamLoginLeft />
              </div>
              <div className="w-[50%] h-full flex items-center justify-center">
                <ExamLoginRight />
              </div>
            </div>
          </div>
        )
        // ) : (
        //   <ExamLoginInUnsupportedBrowser />
        // )
      }
    </>
  );
};

export default Login;
