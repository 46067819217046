import React from "react";
import CameraRight from "./camera/CameraRight";
import MicRight from "./mic/MicRight";
import InternetRight from "./internet/InternetRight";
import InstructionRight from "./instruction/InstructionRight";
// import InstructionRight from "./InstructionRight";
// import RecordRTC from "recordrtc";

const SetupRight = ({
  step,
  hasCameraPermission,
  sethasCameraPermission,
  hasMicPermission,
  sethasMicPermission,
  // setisScreenSharing,
  isMicWorking,
  hasInternetConnection,
}) => {
  // -----------------ScreenRecording-----------------------------------------------------------------
  // const [recording, setRecording] = useState(false);
  // const [stream, setStream] = useState(null);
  // const [recordedBlob, setRecordedBlob] = useState(null);
  // const [showPopup, setShowPopup] = useState(false);
  //   const videoRef = useRef(null);
  //   const webcamRef = useRef();
  // const recorderRef = useRef(null);
  // const [log, setLog] = useState([]);

  // const startRecording = async () => {
  //   try {
  //     const audioStream = await navigator.mediaDevices.getUserMedia({
  //       audio: true,
  //     });
  //     const screenStream = await navigator.mediaDevices.getDisplayMedia({
  //       video: true,
  //     });

  //     if (
  //       screenStream.getVideoTracks()[0].getSettings().displaySurface ===
  //         "monitor" ||
  //       screenStream.getVideoTracks()[0].label === "Primary Monitor"
  //     ) {
  //       const fullStream = new MediaStream([
  //         ...screenStream.getTracks(),
  //         ...audioStream.getTracks(),
  //       ]);
  //       setStream(fullStream);
  //       const recorder = RecordRTC(fullStream, {
  //         type: "video",
  //         mimeType: "video/webm",
  //       });
  //       recorder.startRecording();
  //       recorderRef.current = recorder;
  //       setRecording(true);
  //       addToLog("Started recording");
  //     } else {
  //       setShowPopup(true);
  //       console.log("stoped");
  //     }

  //     screenStream.getVideoTracks()[0].onended = () => {
  //       stopRecording();
  //       setShowPopup(true);
  //       setisScreenSharing(false);
  //       console.log("stoped");
  //     };
  //   } catch (error) {
  //     console.error("Error starting recording:", error);
  //     alert("Failed to start recording. Please try again.");
  //   }
  // };

  // const stopRecording = () => {
  //   // setScreenRecordingPermissions(false)
  //   if (recorderRef.current) {
  //     recorderRef.current.stopRecording(() => {
  //       const blob = recorderRef.current.getBlob();
  //       setRecordedBlob(blob);
  //       setRecording(false);
  //       downloadRecording2(blob);
  //       addToLog("Stopped recording");
  //     });
  //     if (stream) {
  //       stream.getTracks().forEach((track) => track.stop());
  //     }
  //   }
  // };

  // const downloadRecording2 = (recordedBlob) => {
  //   if (recordedBlob) {
  //     const url = URL.createObjectURL(recordedBlob);
  //     const a = document.createElement("a");
  //     const t = Date.now();
  //     a.href = url;
  //     a.download = `screen_recording${t}.webm`;
  //     a.click();
  //     URL.revokeObjectURL(url);
  //   }
  // };

  // const downloadLog = () => {
  //   const logText = log.join("\n");
  //   const blob = new Blob([logText], { type: "text/plain" });
  //   const url = URL.createObjectURL(blob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "log.txt";
  //   a.click();
  //   URL.revokeObjectURL(url);
  // };

  // const addToLog = (message) => {
  //   const timestamp = new Date().toLocaleString();
  //   const logEntry = `${timestamp}: ${message}`;
  //   setLog((prevLog) => [...prevLog, logEntry]);
  // };

  // const handlePopupButtonClick = () => {
  //   setShowPopup(false);
  //   startRecording();
  // };

  //   ------------------------------------------------------

  //   useEffect(() =>{
  //     var stream;
  //     navigator.mediaDevices.getUserMedia({ audio:true })
  //     .then(s => (stream = s), e => console.log(e.message))
  //     .then(() => navigator.mediaDevices.enumerateDevices())
  //     .then(devices => {
  //     console.log("-----------------------------------")
  //     console.log(JSON.stringify(devices))
  //     console.log("-----------------------------------")

  //     console.log(devices.length + " devices.");
  //     devices.forEach(d => console.log(d.kind + ": " + d.label));
  //     })
  //     .catch(e => console.log(e));
  //   },[])
  //
  //   -------------------------------------------------------

  return (
    <>
      {step === "camera" && (
        <CameraRight
          hasCameraPermission={hasCameraPermission}
          sethasCameraPermission={sethasCameraPermission}
          step={step}
        />
      )}

      {step === "mic" && (
        <MicRight
          isMicWorking={isMicWorking}
          hasMicPermission={hasMicPermission}
          sethasMicPermission={sethasMicPermission}
          step={step}
        />
      )}
      {step === "internet" && (
        <InternetRight hasInternetConnection={hasInternetConnection} />
      )}
      {step === "instruction" && <InstructionRight />}

      {/* {step === "screenRecording" && (
        <div className="flex flex-col items-start justify-between h-full w-[80%] mx-auto">
          <div className="flex flex-col">
            <h1 className="heading1 mb-10">Screen Recording instructions</h1>
            <ul className="gap-4 text-[#919397] listDisc">
              <li>
                Do not switch/refresh the tab or try to open any other window.
              </li>
              <li>Do not open the development or any other tool for help.</li>
              <li>Do not move the cursor out of the assessment tab.</li>
              <li>Do not Press 'Back Button' while taking the assessment.</li>
              <li>
                Do not exit 'Full-Screen' mode while taking the assessment.
              </li>
            </ul>
          </div>

          {!recording ? (
            <Button
              version="primary"
              text="Give Permission"
              width="fit-content"
              handleClick={() => startRecording()}
            />
          ) : (
            <Link to="/examInstructions">
              <Button version="primary" text="Proceed" width="fit-content" />
            </Link>
          )}
        </div>
      )} */}
    </>
  );
};

export default SetupRight;
