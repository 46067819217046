import React from "react";
import Timer from "../timer/Timer";

const ExamTopBar = ({
  placememt,
  name,
  participantClass,
  subject,
  loading,
  duration,
  qusNum,
  timerPause,
  timeUp,
}) => {
  return (
    <div className="fixed top-0 left-0 z-10 w-full bg-[#001E3B] py-3">
      <div className="w-[90%] mx-auto flex items-center justify-between">
        <div className="flex items-center gap-10">
          <img
            src="/img/new-home/banner-date.png"
            className="w-[272px]"
            alt=""
          />
          <div className="text-white">
            <h1 className="text-3xl font-semibold capitalize">
              Hello {name} !
            </h1>
            <p className="text-lg">
              Class {participantClass}
              {participantClass === 1
                ? "st"
                : participantClass === 2
                ? "nd"
                : participantClass === 3
                ? "rd"
                : "th"}
              {", "}
              <span className="capitalize">{subject}</span>
            </p>
            <p className="text-lg font-semibold">
              Good luck, and enjoy the journey!
            </p>
          </div>
        </div>
        <div>
          {placememt === "examPage" && (
            <div className="p-3 px-4 text-[#FFFAF0] rounded-[20px] bg-[#E74C3C] shadow-[0px_4px_0px_0px_#C0392B]">
              <Timer
                loading={loading}
                duration={duration}
                qusNum={qusNum}
                timerPause={timerPause}
                timeUp={timeUp}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExamTopBar;
