import React from "react";
import { Button } from "../../../../components";
import { useNavigate } from "react-router-dom";

const InternetRight = ({ setStep, hasInternetConnection }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-start justify-between h-full w-[80%] mx-auto pb-10">
      <div className="flex flex-col">
        <h1 className="heading1 mb-10">Stable Internet Connection</h1>
        <ul className="flex flex-col gap-4 text-[#444] listDisc text-base list-outside list-disc">
          <li>Use a stable internet connection while giving the test.</li>
          <li>
            It is preferred to use a wifi connection rather than using mobile
            hotspot.
          </li>
          <li>
            Press <strong>"Test"</strong> to test your network speed to proceed.
          </li>
        </ul>
      </div>

      <Button
        version="secondary"
        text="Proceed"
        width="8rem"
        handleClick={() => {
          navigate("/examSetup/instruction");
        }}
        isDisabled={!hasInternetConnection}
      />
    </div>
  );
};

export default InternetRight;
