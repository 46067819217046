import { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import * as faceapi from "face-api.js";

const CameraProctoring = ({
  setFaceCount,
  setLoadingPercent,
  setisCameraEnable,
}) => {
  const [modelsLoading, setModelsLoading] = useState(true);
  const [webcamError, setWebcamError] = useState(false);
  useEffect(() => {
    setisCameraEnable(true);
    const loadModels = async () => {
      setModelsLoading(true);
      await faceapi.nets.tinyFaceDetector.loadFromUri("/face-api-models");
      setLoadingPercent((prev) => prev + 5);
      await faceapi.nets.faceRecognitionNet.loadFromUri("/face-api-models");
      setLoadingPercent((prev) => prev + 5);
      await faceapi.nets.faceLandmark68Net.loadFromUri("/face-api-models");
      setLoadingPercent((prev) => prev + 5);
      await faceapi.nets.ssdMobilenetv1.loadFromUri("/face-api-models");
      setLoadingPercent((prev) => prev + 5);

      console.log("Models loaded");
      setModelsLoading(false);
    };

    loadModels();
  }, [setLoadingPercent, setisCameraEnable]);

  const webcamRef = useRef();

  const handleWebCamError = () => {
    setWebcamError(true);
    setisCameraEnable(false);
  };

  const detectFaces = async () => {
    if (webcamRef.current && webcamRef.current.video.readyState === 4) {
      const video = webcamRef.current.video;
      const detections = await faceapi.detectAllFaces(
        video,
        new faceapi.TinyFaceDetectorOptions()
      );
      setFaceCount(detections?.length);
      // if (detections.length === 0) {
      //   console.log("No Face Detected.");
      // } else if (detections.length > 1) {
      //   console.log(
      //     `Detected ${detections.length} faces. Please ensure only one person is in view.`
      //   );
      // } else {
      console.log(detections.length);
      // }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      detectFaces();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-[#888]/40 backdrop-blur-sm p-2 flex flex-col justify-center items-center gap-1">
      {webcamError ? (
        <p className="text-white font-semibold p-2">
          Please enable your webcam.
        </p>
      ) : (
        <>
          <Webcam
            audio={false}
            className="rounded-2xl"
            width={200}
            ref={webcamRef}
            onUserMediaError={handleWebCamError}
            screenshotFormat="image/jpeg"
          />
          <p className="text-sm text-white font-semibold">WebCam Feed</p>
        </>
      )}

      {/* <div className="w-full h-full"><SoundMeter/></div> */}
    </div>
  );
};

export default CameraProctoring;
