import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

const ExamQuestionPanel = ({ questions, qusNum, loading }) => {
  const [question, setQuestion] = useState();
  useEffect(() => {
    setQuestion(questions[qusNum]);
  }, [questions, qusNum]);
  return (
    <div className="w-[50%] h-full p-4 border-r-2 border-[#CCC] text-base">
      {loading ? (
        <div className="w-full h-full flex justify-center items-center">
          <p className="font-bold text-2xl text-[#bbb]">Loading...</p>
        </div>
      ) : (
        <div className="h-full text-[#34495E]">
          <p className="font-semibold text-lg">
            Question
            <span className="ml-2">
              {qusNum + 1}/{questions?.length} :
            </span>
          </p>
          <div className="w-full h-full overflow-y-auto scrollbar-none">
            <div className="mt-4">
              <span className="text-xl font-bold">
                {question?.questionName}
              </span>
            </div>
            <div className="flex flex-col">
              <div className="rounded-xl overflow-hidden flex w-fit my-3">
                {parse(question?.statement)}
              </div>
            </div>

            {/* {question?.questionImg && (
              <div className="w-full my-5 h-[75%] bg-gray-100 rounded-lg text-[#999] flex justify-center items-center relative">
                <img
                  src={question?.questionImg}
                  alt=""
                  className="absolute h-full w-full"
                />
              </div>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamQuestionPanel;
