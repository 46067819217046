import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components";

const InstructionRight = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-start justify-between h-full w-[80%] mx-auto pb-10">
      <div className="flex flex-col">
        <h1 className="heading1 mb-10">Don’t Refresh or Exit Full Screen</h1>
        <ul className="flex flex-col gap-4 text-[#444] listDisc text-base list-outside list-disc">
          <li>
            Do not switch/refresh the tab or try to open any other window.
          </li>
          <li>Do not open the development or any other tool for help.</li>
          <li>Do not move the cursor out of the assessment tab.</li>
          <li>Do not Press 'Back Button' while taking the assessment.</li>
          <li>Do not exit 'Full-Screen' mode while taking the assessment.</li>
        </ul>
      </div>
      <Button
        version="secondary"
        text="Proceed"
        width="8rem"
        // handleClick={() => setStep("screenRecording")}
        handleClick={() => navigate("/examInstructions")}
      />
    </div>
  );
};

export default InstructionRight;
